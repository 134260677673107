<template>
  <div id="modal-reset" class="modal">
    <div class="modal-content">
      <h4 class="grey-text text-darken-2">{{ $t('nav.reset_password') }}</h4>
      <form id="reset-form" role="form" @submit.prevent="resetPassword">
        <div class="input-field">
          <input type="password" id="reset-password" class="validate" v-model="password" required/>
          <label for="reset-password" data-error="Please enter your password.">{{ $t('nav.password') }}</label>
        </div>
        <div class="input-field">
          <input type="password" id="reset-password-confirmation" class="validate" v-model="password_confirmation" required/>
          <label for="reset-password-confirmation">{{ $t('nav.confirm_password') }}</label>
        </div>
        <button type="submit" class="btn orange lighten-2 z-depth-0">{{ $t('nav.send') }}</button><span class="transparent-text">&nbsp;&nbsp;</span>
        <a id="close-reset" class="modal-action modal-close  btn grey z-depth-0" @click="closeReset">
          <i class="fa fa-lock"></i> {{ $t('cancel') }}
        </a>
      </form>
    </div>
  </div>
</template>

<script>
/* eslint no-undef: 0 */

export default {
  name: 'ResetComplete',
  data () {
    return {
      password: '',
      password_confirmation: ''
    }
  },
  props: ['code'],
  components: {
    //
  },

  mounted () {
    this.$nextTick(() => {
      $('#modal-reset').modal({
        dismissible: false,
        inDuration: 500,
        outDuration: 500,
        onCloseStart: () => this.$router.push({ name: 'home' })
      })
    })
  },

  methods: {
    closeOverlay () {
      // $('.sidenav').sidenav('close')
      this.$emit('onCloseOverlay')
    },
    closeReset () {
      this.closeOverlay()
      this.$router.push({ name: 'home' })
    },
    async resetPassword () {
      const data = new URLSearchParams()
      data.append('reset_password_code', this.code)
      data.append('password', this.password)
      data.append('password_confirmation', this.password_confirmation)
      await this.$store.dispatch('resetPassword', data).then((response) => {
        console.log(response.data)
      }).catch((error) => {
        console.log(error.response.data)
      })
    }
  }
}
</script>

<style scoped>

</style>
